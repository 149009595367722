<template>
  <form-view
    :url="`/user/siteuser/${!dataForm.id ? 'save' : 'updateById'}`"
    :data-form="dataForm"
    v-loading="loading"
    :custom-validator="customValidator"
    :before-submit="beforeSubmit"
  >
    <div class="section-title-row">
      <div class="title">用户基本信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="18">
        <el-row :gutter="25">
          <el-col :span="12" v-if="!id">
            <el-form-item label-width="82px" label="用户名" prop="userName">
              <el-input v-model="dataForm.userName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真实姓名" prop="nickName">
              <el-input v-model="dataForm.nickName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="25">
          <el-col :span="12">
            <el-form-item label-width="82px" label="密码" prop="password">
              <el-input autocomplete="new-password" type="password" v-model="dataForm.password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认密码" prop="confirmPassword">
              <el-input autocomplete="new-password" type="password" v-model="dataForm.confirmPassword"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="25">
          <el-col :span="12">
            <el-form-item label-width="82px" label="性别" prop="sex">
              <el-select v-model="dataForm.sex">
                <el-option label="男" value="男"></el-option>
                <el-option label="女" value="女"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label-width="82px" label="联系电话" prop="phone">
              <el-input type="number" v-model="dataForm.phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="25">
          <el-col :span="11">
            <el-form-item label-width="82px" label="所属工地" prop="contractType">
              <el-select filterable v-model="dataForm.siteId">
                <el-option v-for="item in siteList" :key="item.id" :label="item.siteName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="6">
        <image-uploader
          :limit="1"
          @add="addImage($event, 'image')"
          @remove="removeImage('image')"
          :images="imageList || []"
          no-title
        ></image-uploader>
      </el-col>
    </el-row>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'
import { userTypes } from '@/utils/constants'

export default {
  name: 'person-siteuser-update',

  components: { FormView, ImageUploader },

  data () {
    return {
      userTypes,
      loading: false,
      dataForm: {},
      imageList: [],
      siteList: [],
      id: null
    }
  },

  computed: {
    isOwned () {
      return this.dataForm.userType === 1 || this.dataForm.userType === 2
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      let data = await this.$http({
        url: this.$http.adornUrl('/site/list'),
        method: 'post',
        data: {}
      })
      this.siteList = data.datas
      const id = this.id = this.$route.query.id
      this.loading = true
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/user/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          const temp = data.datas
          this.dataForm = temp
          //   this.dataForm.confirmPassword = this.dataForm.password
          console.log(this.dataForm)
          this.loading = false
        }
      } else {
        this.dataForm = {
          userName: '',
          password: '',
          idCare: '',
          sex: '',
          age: '',
          phone: '',
          userType: 3,
          contractType: '',
          contractTime: '',
          nickName: '',
          confirmPassword: '',
          birthDate: '',
          nation: '',
          image: '',
          userAddress: '',
          companyId: '',
          departmentId: '',
          secondDepartmentId: '',
          drivingType: '',
          drivingDate: '',
          drivingFrontImage: '',
          drivingBackImage: '',
          roleIds: [],
          siteId: '',
          isSiteUser: true
        }
        this.loading = false
      }
    },

    addImage (path, name) {
      this.dataForm[name] = path
    },

    removeImage (name) {
      this.dataForm[name] = ''
    },

    customValidator () {
      return true
    },

    clearCompanyId () {
      this.dataForm.companyId = ''
      console.log(this.dataForm)
    },

    beforeSubmit (params) {
      return params
    }
  }
}
</script>

<style lang="scss" scoped>
  .bracket-area {
    .el-form-item__content {
      white-space: nowrap;

      .el-input {
        width: 80%;
      }

      .el-input + label {
        margin-left: 10px;
      }
    }
  }

  .flex-row {
    margin: 20px 0;
    display: flex;
    align-items: center;

    .el-select,
    .el-date-editor {
      width: 150px;
      flex: 0 0 auto;
    }

    label {
      margin: 0 10px;
      white-space: nowrap;
    }

    .el-input.remark {
      flex: 1;
      width: auto;
    }

    .icon {
      font-size: 20px;
      margin-left: 10px;
      cursor: pointer;
      color: #666;

      &:hover {
        color: #999;
      }
    }
  }

  .license-area {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
  }
</style>
